//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

@font-face {
  font-family: 'Avenir-Roman';
  src: url('../../../fonts/Avenir-Roman-12.ttf');
}
