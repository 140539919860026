@import '../../assets/scss/theme.scss';

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.section-label {
  font-family: 'Poppins';
  font-size: 1.313rem;
  letter-spacing: 2.36px;
  color: $gry-01;
  padding-bottom: 0rem;
  margin-top: 4.375rem;
  margin-bottom: 0rem;
  @media (max-width: 992px) {
    margin-top: 1.375rem;
  }
}

.newpass-section-label {
  font-family: 'Poppins';
  font-size: 1.313rem;
  letter-spacing: 2.36px;
  color: $gry-01;
  padding-bottom: 0rem;
  margin-top: 3.813rem;
  margin-bottom: 0rem;
}

.glisten-textfield-label {
  font-family: 'Avenir-Roman';
  font-size: 0.6875rem;
  letter-spacing: 1.24px;
  color: $gry-02;
  padding-top: 0.688rem;
  padding-left: 0.688rem;
  margin-top: 1.313rem;
  margin-bottom: 0rem;
}

.glisten-textfield-lable-hidden {
  visibility: hidden;
}

.body-text {
  font-family: 'Poppins';
  font-weight: lighter;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
  padding-bottom: 0rem;
  margin-top: 1.875rem;
  margin-bottom: 0rem;
}

.sm-body-text {
  font-family: 'Poppins';
  font-weight: lighter;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 1rem;
  color: $gry-01;
  padding-bottom: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.page-background {
  // background: -webkit-linear-gradient(180deg,red 0%, white 100%);
  // background: -o-linear-gradient(180deg,red 0%, white 100%);
  // background: -moz-linear-gradient(180deg,red 0%, white 100%);
  background: linear-gradient(180deg, #eeeeee 0%, white 100%);
}

.account-link-sel {
  background: $gry-09;
  font-family: 'Poppins';
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.099rem;
  color: $gry-01 !important;
  padding-left: 1.438rem;
  padding-right: 1.438rem;
  padding-top: 0.188rem;
  padding-bottom: 0.313rem;
  margin-right: 0.188rem;
}

.account-link {
  font-family: 'Poppins';
  font-size: 0.875rem !important;
  letter-spacing: 0.099rem;
  color: $gry-01 !important;
  padding-left: 1.438rem;
  padding-right: 1.438rem;
  padding-top: 0.188rem;
  padding-bottom: 0.313rem;
  margin-right: 0.188rem;
}
