@import '../../assets/scss/theme.scss';

.update_message {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 1rem;
  color: $blk-00;
  margin-top: 1.25rem;
}

.update_info {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 1rem;
  color: $gry-01;
  margin-top: 0.25rem;
}

.upd-col-title {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 1.313rem;
  letter-spacing: 0.148rem;
  line-height: 1.813rem;
  color: $gry-01;
  margin-top: 7%;
  margin-left: 18%;
  margin-bottom: 7%;
}

.sec-btn-big {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 0.086rem;
  line-height: 1.25rem;
  color: $gry-01 !important;
  background-color: $wht-00 !important;
  border: 0.063rem solid #333333 !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 78%;
  text-align: center;
  align-self: center;
  align-items: unset;
}
