@import '../../assets/scss/theme.scss';

.lane-title {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 1.313rem;
  letter-spacing: 0.148rem;
  line-height: 1.813rem;
  color: $gry-01;
  text-align: center;
  padding-bottom: 0rem;
  margin-top: 3.125rem;
  margin-bottom: 1.875rem !important;
  @media only screen and (max-width: 992px) {
    margin-top: 2.4rem;
    margin-bottom: 1.5rem !important;
    font-weight: 800 !important;
    font-size: 1.7rem;
  }
}

.new-lane {
  border: 0.063rem solid $gry-06 !important;
  border-width: 0rem 0.063rem 0rem 0rem !important;
  padding: 0rem;
}

.progress-lane {
  padding: 0rem;
}

.review-lane {
  border: 0.063rem solid $gry-06 !important;
  border-width: 0rem 0rem 0rem 0.063rem !important;
  padding: 0rem;
}

.lane-select {
  font-family: 'Poppins';
  font-weight: 600 !important;
  background-color: transparent;
  font-size: 1.7rem;
  letter-spacing: 0.148rem;
  line-height: 1.813rem;
  color: $gry-01;
  text-align: center;
  padding-bottom: 0rem;
  // background-image: url(../../assets/images/ic-dropdown.svg) !important;
  // background-repeat: no-repeat;
  // background-position: right 0.75rem center;
  // background-size: initial !important;
  //appearance: none !important;
  align-self: center;
  width: fit-content;
  margin-top: 1rem;
}
