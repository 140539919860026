@import '../../../assets/scss/theme.scss';

.modal-identifier-title {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 1.5rem;
  letter-spacing: 0.169rem;
  line-height: 2.875rem;
  color: $blk-00;
  margin-top: 20px;
}

.modal-identifier-subtitle {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 3rem;
  letter-spacing: 0.338rem;
  line-height: 2.875rem;
  color: $blk-00;
  margin-top: 0.25rem;
}

.vehicle-info-label-alt {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.169rem;
  line-height: 2.063rem;
  color: $gry-01;
}

.modal-gray-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 1rem !important;
  letter-spacing: 0.113rem;
  color: $blk-00 !important;
  background-color: $gry-09 !important;
  border: 0rem solid $gry-09 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.modal-red-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 1rem !important;
  letter-spacing: 0.113rem;
  color: $red-01 !important;
  background-color: $red-02 !important;
  border: 0rem solid $grn-03 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.order-title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $blk-00;
}

.order-body {
  font-family: 'Poppins';
  font-weight: 200;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.438rem;
  color: $blk-00;
}

.services-title {
  font-family: 'Poppins';
  font-weight: 200;
  font-size: 1.125rem;
  letter-spacing: 0.127rem;
  line-height: 1.563rem;
  color: $blk-00;
  margin-bottom: 1.438rem;
}

.prim-modal-btn {
  float: right;
  margin-top: 1.75rem !important;
  margin-bottom: 1.688rem !important;
  margin-right: 2.375rem;
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 15.375rem;
}

.sec-modal-btn {
  float: right;
  margin-top: 1.75rem !important;
  margin-bottom: 1.688rem !important;
  margin-right: 2.375rem;
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $gry-03 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 15.375rem;
}

.modal-service-row {
  border: 0.063rem solid $gry-06 !important;
}

.modal-border-bot {
  border: 0.063rem solid $gry-06 !important;
  border-width: 0rem 0rem 0.063rem 0rem !important;
}
