@import '../../assets/scss/theme.scss';

.modal-title {
  color: $gry-01;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.127rem;
  // padding-left: 4.375rem;
  // padding-top: 4rem;
}
