@import '../../assets/scss/theme.scss';

[class*='rbd-'] {
  background: black;
}

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.glsn-box {
  display: flex;
  flex-direction: row;
  border: 1px solid #c0c0c0 !important;
  background-color: transparent;
  box-shadow: none;
}

.srch-inpt {
  flex-basis: auto;
  border: none !important;
  background: transparent !important;
  padding-left: 1.125rem !important;
  padding-top: 0.75rem !important;
  padding-right: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  //   @media only screen and (max-width: 992px) {
  //     padding-left: 0.5rem !important;
  //     padding-right: 0.1rem !important;
  //   }
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.099rem;
  line-height: 1.25rem !important;
  color: $gry-01 !important;
  text-transform: uppercase;
  outline-width: 0;
}

.inpt-icon {
  border: none;
  background: transparent;
  background-color: transparent !important;
  border: none !important;
  padding-left: 1.375rem !important;
  padding-right: 0rem !important;
}

.wb-content {
  border: 0.063rem solid $gry-07 !important;
  border-width: 0.063rem 0rem 0rem;
  padding: 0rem 0rem 4.375rem 0rem !important;
}
