//
// Glisten - Login page - Custom Styling
//

@import '../../assets/scss/theme.scss';

.form {
  background: #0e101c;
  max-width: 400px;
  margin: 20rem auto;
}

.top-page-margin {
  margin-top: 9.188rem;
  @media only screen and (max-width: 992px) {
    margin-top: 4.188rem;
  }
}

.login-logo {
  margin-top: 4.563rem;
  margin-bottom: 3.563rem;
}

.glisten-textfield-label {
  font-family: 'Avenir-Roman';
  font-size: 0.6875rem;
  letter-spacing: 1.24px;
  color: $gry-02;
  padding-top: 0.688rem;
  padding-left: 0.688rem;
  margin-bottom: 0rem;
}

p {
  color: #dd1010;
}

.glisten-textfield {
  border: 0 !important;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid $blk-00 !important;
  border-radius: 0 !important;
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $blk-00 !important;
  padding-top: 0.375rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.login-email-field {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
  @media only screen and (max-width: 992px) {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
}

.login-pass-field {
  margin-top: 1.688rem;
  margin-left: 6.25rem;
  margin-right: 6.25rem;
  @media only screen and (max-width: 992px) {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
}

.login-btn {
  margin-top: 2.125rem;
  //   margin-left: 9.188rem;
  //   margin-right: 9.188rem;
  //   @media only screen and (max-width: 992px) {
  //     margin-left: 2.25rem;
  //     margin-right: 2.25rem;
  //   }
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  //   padding-left: 3.5rem;
  //   padding-right: 3.5rem;
}

.login-link {
  font-family: 'Poppins';
  font-weight: lighter !important;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $blk-00 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 2.563rem;
  margin-bottom: 6.625rem;
}
