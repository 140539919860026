@import '../../../assets/scss/theme.scss';

.glsn-box {
  display: flex;
  flex-direction: row;
  border: 1px solid #c0c0c0 !important;
  background-color: transparent;
  box-shadow: none;
}

.search-inpt {
  flex-basis: auto;
  border: none !important;
  background: transparent !important;
  padding-left: 1.25rem !important;
  padding-top: 0.75rem !important;
  padding-right: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.099rem;
  line-height: 1.25rem !important;
  color: $blk-00 !important;
  text-transform: uppercase;
}

.finance-inpt-icon {
  border: none;
  background: transparent;
  background-color: transparent !important;
  border: none !important;
  padding-left: 0rem;
  padding-right: 1.375rem;
}

.finance-inpt-icon:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
  box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
}
