//
// _horizontal.scss
//

.topnav {
  background: $topnav-bg;
  padding: 0 calc(#{$grid-gutter-width} / 5);
  box-shadow: none;
  margin-top: $header-height - 60px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;

  .topnav-menu {
    margin: 0;
    padding: 0;
  }

  .navbar-nav {
    .nav-link {
      font-size: 14px;
      position: relative;
      padding: 1rem 1.3rem;
      color: $menu-item-color;
      i {
        font-size: 15px;
      }
      &:focus,
      &:hover {
        color: $menu-item-active-color;
        background-color: transparent;
      }
    }

    .dropdown-item {
      color: $menu-item-color;
      &.active,
      &:hover {
        color: $menu-item-active-color;
      }
    }

    .nav-item {
      .nav-link.active {
        color: $menu-item-active-color;
      }
    }

    .dropdown {
      &.active {
        > a {
          color: $menu-item-active-color;
          background-color: transparent;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  body[data-layout='horizontal'] {
    .container-fluid,
    .navbar-header {
      max-width: 82%;
    }
    .breadcrumb-header {
      position: relative;
      max-width: 100%;
      height: 4.5rem;
      background-color: white;
      margin-top: -1.2rem;
      .page-title-box {
        padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width})
          calc(#{$grid-gutter-width} / 2) calc(#{$grid-gutter-width} * 2);
      }
      .filter-box {
        padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width} * 2) 0 0;
        display: flex;
        justify-content: flex-end;
        .vin-search {
          .form-control {
            width: 150px;
            padding-left: 1rem;
            box-shadow: none;
          }
          span {
            position: absolute;
            z-index: 10;
            font-size: 16px;
            line-height: 38px;
            left: 120px;
            top: 0;
            color: #032eff;
          }
        }
      }
    }
  }
}

// @include media-breakpoint-up(lg) {
//   .breadcrumb-header {
//     max-width: 100%;
//     height: 4.5rem;
//     background-color: white;
//     margin-top: -1.2rem;
//     .page-title-box {
//       padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width})
//         calc(#{$grid-gutter-width} / 2) calc(#{$grid-gutter-width} / 2);
//     }
//     .filter-box {
//       padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width}) 0 0;
//       display: flex;
//       justify-content: flex-end;
//       .vin-search {
//         .form-control {
//           width: 130px;
//           padding-left: 1rem;
//           box-shadow: none;
//         }
//         span {
//           position: absolute;
//           z-index: 10;
//           font-size: 16px;
//           line-height: 38px;
//           left: 100px;
//           top: 0;
//           color: #032eff;
//         }
//       }
//     }
//   }

//   .topnav {
//     .navbar-nav {
//       .nav-item {
//         &:first-of-type {
//           .nav-link {
//             padding-left: 0;
//           }
//         }
//       }
//     }

//     .dropdown-item {
//       padding: 0.5rem 1.5rem;
//       min-width: 180px;
//     }

//     .dropdown {
//       &.mega-dropdown {
//         // position: static;
//         .mega-dropdown-menu {
//           left: 0px;
//           right: auto;
//         }
//       }
//       .dropdown-menu {
//         margin-top: 0;
//         border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

//         .arrow-down {
//           &::after {
//             right: 15px;
//             transform: rotate(-135deg) translateY(-50%);
//             position: absolute;
//           }
//         }

//         .dropdown {
//           .dropdown-menu {
//             position: absolute;
//             top: 0 !important;
//             left: 100%;
//             display: none;
//           }
//         }
//       }

//       &:hover {
//         > .dropdown-menu {
//           display: block;
//         }
//       }
//     }

//     .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
//       display: block;
//     }
//   }

//   .navbar-toggle {
//     display: none;
//   }

//   // .glisten-textfield {
//   //   border: 0;
//   //   outline: 0;
//   //   background: transparent;
//   //   border-bottom: 1px solid black;
//   // }

//   // .glisten-textfield-label {
//   //   margin-bottom: 0rem;
//   //   padding-bottom: 0rem;
//   //   font-size: 0.7rem;
//   //   margin-left: 0.2rem;
//   // }

//   // .glisten-textfield-label-hidden {
//   //   visibility: hidden;
//   // }
// }

.arrow-down {
  display: inline-block;

  &:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: '';
    height: 0.4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    transition: all 0.3s ease-out;
    width: 0.4em;
  }
}

@media only screen and (max-width: 992px) {
  .topnav-menu {
    .navbar-nav {
      li {
        &:last-of-type {
          .dropdown {
            .dropdown-menu {
              right: 100%;
              left: auto;
            }
          }
        }
      }
    }
  }
}
// @include media-breakpoint-down(xl) {
//   .topnav-menu {
//     .navbar-nav {
//       li {
//         &:last-of-type {
//           .dropdown {
//             .dropdown-menu {
//               right: 100%;
//               left: auto;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 992px) {
  .breadcrumb-header {
    max-width: 100%;
    height: fit-content;
    background-color: white;
    margin-top: -1.2rem;
    margin-bottom: 1rem;
    .page-title-box {
      padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width})
        calc(#{$grid-gutter-width} / 2) calc(#{$grid-gutter-width} / 2);
    }
  }

  .filter-box {
    padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width}) 0 0;
    display: flex;
    justify-content: flex-end;
    .vin-search {
      .form-control {
        width: 130px;
        padding-left: 1rem;
        box-shadow: none;
      }
      span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: 38px;
        left: 100px;
        top: 0;
        color: #032eff;
      }
    }
  }

  .navbar-brand-box {
    .logo-dark {
      display: $display-block;
      span.logo-sm {
        display: $display-block;
      }
    }

    .logo-light {
      display: $display-none;
    }
  }

  .topnav {
    background: $topnav-bg;
    margin-top: 70px;
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
    .navbar-nav {
      .nav-link {
        padding: 0.75rem 1.1rem;
      }
    }

    .dropdown {
      .dropdown-menu {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding-left: 15px;
        &.dropdown-mega-menu-xl {
          width: auto;

          .row {
            margin: 0px;
          }
        }
      }

      .dropdown-item {
        position: relative;
        background-color: transparent;

        &.active,
        &:active {
          color: $primary;
        }
      }
    }

    .arrow-down {
      &::after {
        right: 15px;
        position: absolute;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  body[data-layout='horizontal'][data-topbar='light'] {
    .navbar-brand-box {
      .logo-dark {
        display: $display-block;
      }

      .logo-light {
        display: $display-none;
      }
    }

    .topnav {
      background: transparent;
      display: block;
      margin-right: -3rem;
      z-index: 1004;
      .navbar-nav {
        .nav-link {
          color: rgba($black, 0.6);

          &:focus,
          &:hover {
            color: rgba($black, 0.8);
          }
        }

        > .dropdown {
          &.active {
            > a {
              text-decoration: underline !important;
              color: rgba($black, 1) !important;
            }
          }
        }
      }
    }
  }
}
