@import '../../assets/scss/theme.scss';

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.ord-subheader {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 1rem;
  @media only screen and (max-width: 992px) {
    font-weight: 800 !important;
    font-size: 1.5rem;
  }
  letter-spacing: 0.113rem;
  line-height: 1.438rem;
  color: $gry-01;
  margin-bottom: 1.5rem;
}

.vech-info-row-title {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.5rem;
  color: $blk-00;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-left: 0.875rem !important;
  margin-bottom: 0 !important;
}

.vech-info-row-data {
  font-family: 'Poppins';
  font-weight: 200 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.5rem;
  color: $blk-00;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2%;
  margin-left: 0.375rem !important;
  margin-bottom: 0 !important;
}

.request-line-1 {
  font-family: 'Avenir-Roman';
  font-weight: 500 !important;
  font-size: 0.625rem;
  letter-spacing: 0.152rem;
  line-height: 1.5rem;
  color: $gry-01;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
  margin-bottom: 0;
  margin-left: 2.5rem;
}

.request-line-2 {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.5rem;
  color: $blk-00;
  padding-bottom: 0.063rem;
  margin-bottom: 0;
  margin-left: 2.5rem;
}

.request-line-3 {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  line-height: 1.5rem;
  color: $gry-02;
  margin-bottom: 1rem;
  margin-left: 2.5rem;
}

.identifier-title {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 3rem;
  letter-spacing: 0.338rem;
  line-height: 2.875rem;
  color: $blk-00;
  margin-left: 3.75rem;
  margin-top: 3.75rem;
  margin-bottom: 3.125rem;
  @media only screen and (max-width: 992px) {
    font-size: 2rem;
    margin-top: 1.75rem;
    margin-bottom: 1.125rem;
    margin-left: 1.75rem;
  }
}

.identifier-subtitle {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 1.5rem;
  letter-spacing: 0.169rem;
  line-height: 2.875rem;
  color: $blk-00;
  margin-left: 1rem;
  margin-top: 3.75rem;
  margin-bottom: 3.125rem;
  @media only screen and (max-width: 992px) {
    font-size: 1rem;
    margin-top: 1.75rem;
  }
}

.prim-cta-bgr {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1.125rem !important;
  letter-spacing: 0.127rem;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: 0rem solid $gry-06 !important;
  border-radius: 0rem !important;
  padding-top: 0.875rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  width: 39%;
  margin-left: 3.75rem;
  margin-bottom: 3.438rem;
  align-items: unset;
}

.gray-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  letter-spacing: 0.113rem;
  color: $blk-00 !important;
  background-color: $gry-09 !important;
  border: 0rem solid $gry-09 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.white-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  letter-spacing: 0.113rem;
  color: $blk-00 !important;
  background-color: $wht-00 !important;
  border: 0rem solid $gry-09 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.yellow-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  letter-spacing: 0.113rem;
  color: $blk-00 !important;
  background-color: $yel-02 !important;
  border: 0rem solid $gry-09 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.green-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  letter-spacing: 0.113rem;
  color: $blk-00 !important;
  background-color: $grn-03 !important;
  border: 0rem solid $grn-03 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.red-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  letter-spacing: 0.113rem;
  color: $red-01 !important;
  background-color: $red-02 !important;
  border: 0rem solid $grn-03 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.done-late-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  letter-spacing: 0.113rem;
  color: $wht-00 !important;
  background-color: #b90000 !important;
  border: 0rem solid $grn-03 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.done-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  letter-spacing: 0.113rem;
  color: $blk-00 !important;
  background-color: #21b900 !important;
  border: 0rem solid $grn-03 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-bottom: 0 !important;
}

.service-card {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18) !important;
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
  @media only screen and (max-width: 992px) {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  margin-bottom: 1.563rem !important;
}

.service-group-img {
  margin-top: 1.875rem;
  margin-left: 1.563rem;
  @media only screen and (max-width: 992px) {
    margin-left: 0rem;
  }
  margin-bottom: 2.25rem;
  width: 2.5rem;
  height: 2.5rem;
}

.service-line-1 {
  //   margin-left: -1rem;
  margin-top: 1.563rem;
  margin-bottom: 0.375rem;
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  color: $blk-00;
  text-indent: 6.5em;
}

.service-line-2 {
  //   margin-left: -1rem;
  margin-bottom: 1.375rem;
  font-family: 'Poppins';
  font-weight: 200 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  color: $blk-00;
  text-indent: 6.5em;
}

.note-text {
  margin-bottom: 0rem;
  width: 100%;
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.875rem;
  letter-spacing: auto;
  line-height: 1.5rem;
  color: $blk-00;
}

.assigned-name {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.5rem;
  color: $blk-00;
}

.assigned-avatar {
  font-family: 'Poppins';
  font-size: 1rem !important;
  letter-spacing: auto;
  line-height: 1.5;
  font-weight: 400;
}

.image-gallery-image {
  border-radius: 8px !important;
}

.image-gallery {
  width: 93% !important;
}

.image-gallery-index {
  border-radius: 8px !important;
}

.image-gallery-thumbnails {
  padding-top: 0.938rem;
  padding-bottom: 0rem;
}

.secondary-btn-sm {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 0.086rem;
  line-height: 1.25rem;
  color: $gry-01 !important;
  background-color: $wht-00 !important;
  border: 0.063rem solid #333333 !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 78%;
  text-align: center;
  align-self: center;
  align-items: unset;
}

.inpt-icon {
  background: transparent;
  background-color: transparent !important;
  border: 0rem !important;
}
