@import '../../../assets/scss/theme.scss';

.cleaner-contact {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.625rem;
  color: $blk-00;
  margin-bottom: 0px !important;
}

.invoice-header {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $blk-00;
  background-color: $gry-09 !important;
  padding-top: 0.313rem !important;
  padding-bottom: 0.313rem !important;
}

.invoice-cells {
  font-family: 'Poppins';
  font-weight: 200 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $blk-00;
  padding-top: 0.563rem !important;
  padding-bottom: 0.563rem !important;
  vertical-align: middle;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
