@import '../../assets/scss/theme.scss';

.order-sum {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 1rem;
  letter-spacing: 0.113rem;
  line-height: 1.438rem;
  color: $gry-01;
  margin-bottom: 1.875rem;
  padding-left: 0.5rem !important;
}

.no-border {
  border-bottom: 0px solid #929292 !important;
}

.table-border {
  border: 1px solid #e6e6e6 !important;
}

.border-top {
  border-top: 1px solid #929292 !important;
}
