//
// Glisten - Header
//

@import '../../assets/scss/theme.scss';

.header-logo-title {
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 1rem !important;
  letter-spacing: 1.8px;
  color: $gry-01 !important;
  margin-bottom: 0rem;
  @media only screen and (max-width: 992px) {
    margin-left: 3.25rem;
    float: left;
    display: contents;
  }
}
