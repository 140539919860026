@import '../../assets/scss/theme.scss';

.glisten-dropdown {
  border: 0 !important;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid $blk-00 !important;
  border-radius: 0 !important;
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $blk-00 !important;
  padding-top: 0.375rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.glisten-dropdown-unselected {
  border: 0 !important;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid $blk-00 !important;
  border-radius: 0 !important;
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $gry-02 !important;
  padding-top: 0.375rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.form-select {
  background-image: url(../../assets/images/ic-dropdown.svg) !important;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: initial !important;
}
