//
// Glisten - Modal with Dynamic Content
//

@import '../../assets/scss/theme.scss';

.glisten-modal-header {
  border-bottom: none !important;
  padding: 0rem !important;
}

.glisten-modal-title {
  color: $gry-01;
  font-size: 1.125rem;
  font-weight: 400;
  padding-left: 2.375rem;
  padding-top: 4rem;

  @media only screen and (max-width: 992px) {
    padding-left: 1.375rem !important;
    padding-top: 3rem !important;
  }
}

.close-btn {
  border: none !important;
  background: transparent !important;
  padding: 0rem;
  margin-top: 2.063rem;
  margin-right: 1.438rem;
}

.close-btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
  box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto !important;
}

.modal-dialog {
  height: calc(100% - 3.5rem) !important;
}

.modal-body {
  overflow-y: auto !important;
}

.glisten-modal-body {
  @media only screen and (max-width: 992px) {
    padding-left: 1.375rem !important;
    padding-right: 1.375rem !important;
  }
  padding-left: 4.375rem !important;
  padding-bottom: 0;
}

.glisten-modal-footer {
  padding: 0 !important;
}

.prim-mod-action {
  float: right;
  margin-top: 1.75rem;
  margin-bottom: 1.688rem;
  margin-right: 2.375rem;
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  @media only screen and (max-width: 992px) {
    width: 40% !important;
    margin-right: 1rem !important;
  }
}

.alt-mod-action {
  float: right;
  margin-top: 1.75rem;
  margin-bottom: 1.688rem;
  margin-right: 2.375rem;
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $gry-01 !important;
  background-color: $wht-00 !important;
  border: 0.063rem solid #333333 !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 15.375rem;
}

.back-link {
  margin-top: 1.75rem;
  margin-bottom: 1.688rem;
  margin-left: 2.375rem;
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.813rem !important;
  letter-spacing: 0.091rem;
  line-height: 1.188rem !important;
  color: $blk-00 !important;
  @media only screen and (max-width: 992px) {
    margin-left: 1.375rem !important;
  }
}

.back-link:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
  box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
}
