@import '../../assets/scss/theme.scss';

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.subpage-title {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 1.313rem;
  letter-spacing: 0.148rem;
  line-height: 1.813rem;
  color: $gry-01;
  padding-bottom: 0rem;
  margin-top: 2.188rem;
  margin-left: 1.875rem;
  margin-bottom: 0 m !important;
}

.prim-btn-big {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 13.75rem !important;
}

.disabled-prim-btn-big {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $gry-05 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 13.75rem !important;
}

.table-headers {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 2.25rem;
  color: $blk-00;
  vertical-align: middle !important;
  border-bottom: 1px solid #929292 !important;
}

.table-cells {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 1.25rem;
  color: $blk-00;
  padding-top: 1.188rem !important;
  padding-bottom: 1.188rem !important;
  vertical-align: middle !important;
}

.page-background {
  // background: -webkit-linear-gradient(180deg,red 0%, white 100%);
  // background: -o-linear-gradient(180deg,red 0%, white 100%);
  // background: -moz-linear-gradient(180deg,red 0%, white 100%);
  background: linear-gradient(180deg, #eeeeee 0%, white 100%);
}

.account-link-sel {
  background: $gry-09;
  font-family: 'Poppins';
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.099rem;
  color: $gry-01 !important;
  padding-left: 1.438rem;
  padding-right: 1.438rem;
  padding-top: 0.188rem;
  padding-bottom: 0.313rem;
  margin-right: 0.188rem;
}

.account-link {
  font-family: 'Poppins';
  font-size: 0.875rem !important;
  letter-spacing: 0.099rem;
  color: $gry-01 !important;
  padding-left: 1.438rem;
  padding-right: 1.438rem;
  padding-top: 0.188rem;
  padding-bottom: 0.313rem;
  margin-right: 0.188rem;
}

.unselected-sort {
  color: $gry-06;
  margin-left: 0.75rem;
}

.selected-sort {
  color: $grn-02;
  margin-left: 0.75rem;
}

.hide {
  visibility: hidden;
}

.thin-table-cell {
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $blk-00;
  padding-top: 0.313rem !important;
  padding-bottom: 0.313rem !important;
}

.time-stamp-info {
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $blk-00;
  padding-bottom: 0rem !important;
}

.vin-prom {
  font-weight: 600;
  color: $blk-00;
}
