@import '../../assets/scss/theme.scss';

.wb-card {
  width: 75%;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.18) !important;
  margin-bottom: 3.125rem !important;
  white-space: normal !important;
  //align-self: center;
  @media only screen and (max-width: 992px) {
    width: 85%;
  }
}

.wb-card-late {
  width: 75%;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.18) !important;
  border: 0.25rem solid $red-01 !important;
  white-space: normal !important;
  @media only screen and (max-width: 992px) {
    width: 85%;
  }
}

.row-margin-fix {
  margin: 0rem !important;
}

.ident-label-done-late {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  line-height: 1.063rem;
  color: $wht-00;
  text-align: center;
  background-color: #b90000;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  width: 95%;
  align-self: center !important;
  margin-top: 0.75rem;
  margin-bottom: 0.938rem;
}

.ident-label-done {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  line-height: 1.063rem;
  color: $blk-00;
  text-align: center;
  background-color: #21b900;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  width: 95%;
  align-self: center !important;
  margin-top: 0.75rem;
  margin-bottom: 0.938rem;
}

.ident-label-urgent {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  line-height: 1.063rem;
  color: $red-01;
  text-align: center;
  background-color: $red-02;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  width: 95%;
  align-self: center !important;
  margin-top: 0.75rem;
  margin-bottom: 0.938rem;
}

.ident-label {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  line-height: 0.75rem;
  color: $blk-00;
  text-align: center;
  background-color: $gry-09;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  width: 95%;
  align-self: center !important;
  margin-top: 0.75rem;
  margin-bottom: 0.938rem;
}

.due-time {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  line-height: 0.75rem;
  color: $blk-00;
  text-align: center;
  align-self: center !important;
  //margin-top: 0.438rem;
  margin-bottom: 0rem;
  width: 95%;
}

.due-time-urgent {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.75rem;
  letter-spacing: 0.084rem;
  line-height: 0.75rem;
  color: $red-01;
  text-align: center;
  align-self: center !important;
  //margin-top: 0.438rem;
  margin-bottom: 0rem;
  width: 95%;
}

.ident-value {
  font-family: 'Poppins';
  font-weight: 700 !important;
  font-size: 2.25rem;
  letter-spacing: 0.253rem;
  line-height: 2.25rem;
  color: $blk-00;
  text-align: center;
  width: 95%;
  align-self: center !important;
  margin-bottom: 1.875rem;
  margin-top: 0.938rem;
}

.card-image {
  display: block;
  max-width: 104px;
  height: auto;
  width: 56%;
  border-radius: 8px;
  align-self: left;
  margin-left: 35%;
}

.year-make-label {
  font-family: 'Poppins';
  font-weight: 700 !important;
  font-size: 1rem;
  letter-spacing: 0.113rem;
  line-height: 1.438rem;
  color: $gry-02;
  text-align: left;
  margin-right: 20%;
  margin-bottom: 0rem !important;
}

.model-label {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.875rem;
  letter-spacing: 0.113rem;
  line-height: 1.438rem;
  color: $gry-02;
  text-align: left;
  margin-right: 20%;
  margin-bottom: 0rem !important;
}

.prim-action {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: 0rem solid $gry-06 !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 75%;
  align-self: center;
  align-items: unset;
}

.alt-action {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $gry-01 !important;
  background-color: $wht-00 !important;
  border: 0.063rem solid #333333 !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 75%;
  text-align: center;
  align-self: center;
  align-items: unset;
}

.link-action {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $gry-02 !important;
  background-color: $wht-00 !important;
  border: 0rem solid #333333 !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 75%;
  text-align: center;
  align-self: center;
  align-items: unset;
}

.hide {
  visibility: hidden;
}

.info-row-title {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
  padding-top: 0.813rem;
  padding-bottom: 1.063rem;
  margin-left: 1.5rem !important;
  margin-bottom: 0 !important;
}

.info-row-info {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
  padding-top: 0.813rem;
  padding-bottom: 1.063rem;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  margin-bottom: 0 !important;
}

.service-label {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
  //margin-left: 1rem;
  margin-bottom: 0 !important;
}

.note-body {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
  margin-top: 0.5rem !important;
  margin-bottom: 0 !important;
  margin-left: 8%;
  margin-right: 8%;

  //padding-top: 0.813rem;
  padding-bottom: 1.063rem;
}

.review-row {
  background-color: $gry-10;
}

.review-row-urgent {
  background-color: $red-02;
}

.review-row-title {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 1.125rem;
  letter-spacing: 0.127rem;
  line-height: 1.563rem;
  color: $gry-01;
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.review-card-btn {
  margin-bottom: 1.5rem;
}

.default-card-btn {
  margin-bottom: 1rem;
}
