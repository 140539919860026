//
// Glisten -
//

@import '../../../../assets/scss/theme.scss';

.glisten-modal-header {
  border-bottom: none !important;
  padding: 0rem !important;
}

.glisten-modal-title {
  color: $gry-01;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.127rem;
  padding-left: 4.375rem;
  padding-top: 4rem;
  @media only screen and (max-width: 992px) {
    padding-left: 1.375rem !important;
    padding-top: 3rem !important;
  }
}

.close-btn {
  border: none !important;
  background: transparent !important;
  padding: 0rem;
  margin-top: 2.063rem;
  margin-right: 1.438rem;
}

.glisten-modal-body {
  padding-left: 4.375rem;
  padding-bottom: 0;
  @media only screen and (max-width: 992px) {
    padding-left: 1.375rem !important;
    padding-right: 1.375rem !important;
  }
}

.glisten-modal-footer {
  padding: 0 !important;
}

.prim-mod-action {
  float: right;
  margin-top: 1.75rem;
  margin-bottom: 1.688rem;
  margin-right: 2.375rem;
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 15.375rem;
}

.alt-mod-action {
  float: right;
  margin-top: 1.75rem;
  margin-bottom: 1.688rem;
  margin-right: 2.375rem;
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $gry-01 !important;
  background-color: $wht-00 !important;
  border: 0.063rem solid #333333 !important;
  border-radius: 0 !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.688rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 15.375rem;
}

//^^^^^^everything above is for standard modal header/footer styling

.glisten-textfield-label {
  font-family: 'Avenir-Roman';
  font-size: 0.6875rem;
  letter-spacing: 1.24px;
  color: $gry-02;
  padding-top: 0.688rem;
  padding-left: 0.688rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.glisten-textfield-label-hidden {
  visibility: hidden;
}

.dropdown-field {
  border: 0 !important;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid $blk-00 !important;
  border-radius: 0 !important;
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $blk-00 !important;
  padding-top: 0.375rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  background-image: url(../../../../assets/images/ic-dropdown.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}
