@import '../../../assets/scss/theme.scss';

.col-print-1 {
  width: 8% !important;
  float: left;
}
.col-print-2 {
  width: 16% !important;
  float: left;
}
.col-print-3 {
  width: 25% !important;
  float: left;
}
.col-print-4 {
  width: 33% !important;
  float: left;
}
.col-print-5 {
  width: 42% !important;
  float: left;
}
.col-print-6 {
  width: 50% !important;
  float: left;
}
.col-print-7 {
  width: 58% !important;
  float: left;
}
.col-print-8 {
  width: 66% !important;
  float: left;
}
.col-print-9 {
  width: 75% !important;
  float: left;
}
.col-print-10 {
  width: 83% !important;
  float: left;
}
.col-print-11 {
  width: 92% !important;
  float: left;
}
.col-print-12 {
  width: 100% !important;
  float: left;
}

.time-stamp-info {
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $blk-00;
  padding-bottom: 0rem !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
