@import '../../../assets/scss/theme.scss';

@include media-breakpoint-up(xl) {
  .vin-text {
    display: grid;
    align-items: flex-end;
  }
  .img-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-next-vin {
    width: 17rem;
  }
}

@include media-breakpoint-up(lg) {
  .vin-text {
    display: grid;
    align-items: flex-end;
  }
  .img-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-next-vin {
    width: 17rem;
  }
}

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.invalid-feedback {
  padding-top: 0.25rem !important;
  padding-left: 0.75rem !important;
}

.form-select:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
  box-shadow: 0 0 0 0rem rgba(85, 110, 230, 0.25) !important;
}

.history-title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
  margin-bottom: 1.875rem !important;
  margin-top: 2.5rem !important;
}

.no-past {
  font-family: 'Poppins';
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
}

.duplicate-VIN-title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $red-01;
  margin-bottom: 1rem !important;
  margin-top: 0rem !important;
}
