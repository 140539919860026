@import '../../assets/scss/theme.scss';

.service-box {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
}

.group-title {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.813rem;
  letter-spacing: 0.091rem;
  line-height: 1.188rem;
  color: $blk-00;
}

.select-counter {
  margin-left: 0.375rem;
  font-family: 'Poppins';
  font-weight: 700 !important;
  font-size: 1rem;
  letter-spacing: 0.113rem;
  line-height: 1.438rem;
  color: $blk-00;
}

// top section of the accordion when it is collapsed
.accordion-button.collapsed {
  background-color: $wht-00 !important;
  border: none !important;
  padding: 0 !important;
}

.accordion-button:not(.collapsed) {
  background-color: $wht-00 !important;
  border: none !important;
  padding: 0 !important;
}

// top section of the accordion when it is collapsed AND has selections within
.accordion-button.collapsed.selected {
  background-color: $wht-00 !important;
  border: solid $blu-01 !important;
  border-width: 1px !important;
}

.accordion-button.selected:not(.collapsed) {
  background-color: $wht-00 !important;
  border: solid $blu-01 !important;
  border-width: 1px 1px 0 !important;
}

.accordion-collapse {
  border: solid $blu-01 !important;
  border-width: 0 !important;
}

.accordion-collapse.selected {
  border: solid $blu-01 !important;
  border-width: 0 1px 1px !important;
}

.accordion-button::after {
  width: 23px !important;
  height: 23px !important;
  margin-right: 1.688rem !important;
  background-image: url('../../assets/images/ic-plus.svg') !important;
  background-size: 23px !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('../../assets/images/ic-minus.svg') !important;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.form-check-input {
  width: 1.25rem !important;
  height: 1.25rem !important;
  margin-top: 0rem !important;
  // vertical-align: top;
  //background-color: $gry-11 !important;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: contain;
  border: 1px solid $gry-04 !important;
  border-radius: 0 !important;
}

.price-data {
  padding-left: 1.5rem !important;
  padding-right: 0 !important;
  padding-top: 0.813rem !important;
}

.price {
  font-family: 'Poppins';
  font-weight: 700 !important;
  font-size: 0.813rem;
  letter-spacing: 0.091rem;
  line-height: 1.188rem;
  color: $blk-00;
}

.service-label {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.813rem;
  letter-spacing: 0.091rem;
  line-height: 1.188rem;
  color: $blk-00;
}
