@import '../../assets/scss/theme.scss';

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.profile-label {
  font-family: 'Avenir-Roman';
  font-size: 0.6875rem;
  letter-spacing: 1.24px;
  color: $gry-02;
  padding-top: 0.688rem;
  padding-left: 0.688rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  margin-top: 1.25rem;
}

.profile-input-label {
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $blk-00 !important;
  padding-top: 0.375rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.secondary-btn-sm {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.688rem !important;
  letter-spacing: 0.078px;
  color: $gry-01 !important;
  background-color: $wht-00 !important;
  border: '1px solid #929292';
  border-radius: 0rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.563rem !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  width: 8.875rem;
}

.department-label {
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $gry-02 !important;
  margin-top: 1.5rem;
  margin-bottom: 0rem !important;
}

.acccounttype-label {
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $blk-00 !important;
  margin-top: 0.563rem;
  margin-bottom: 0rem !important;
}

.prim-btn-sm {
  font-family: 'Poppins'; 
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.688rem !important;
  letter-spacing: 0.078rem;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.563rem !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  width: auto;
}

.page-background {
  // background: -webkit-linear-gradient(180deg,red 0%, white 100%);
  // background: -o-linear-gradient(180deg,red 0%, white 100%);
  // background: -moz-linear-gradient(180deg,red 0%, white 100%);
  background: linear-gradient(180deg,#EEEEEE 0%, white 100%);
}

.account-link-sel {
  background: $gry-09;
  font-family: 'Poppins';
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.099rem;
  color: $gry-01 !important;
  padding-left: 1.438rem;
  padding-right: 1.438rem;
  padding-top: 0.188rem;
  padding-bottom: 0.313rem;
  margin-right: 0.188rem;
}

.account-link {
  font-family: 'Poppins';
  font-size: 0.875rem !important;
  letter-spacing: 0.099rem;
  color: $gry-01 !important;
  padding-left: 1.438rem;
  padding-right: 1.438rem;
  padding-top: 0.188rem;
  padding-bottom: 0.313rem;
  margin-right: 0.188rem;
}

.table-cells {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 1.25rem;
  color: $blk-00;
  padding-top: 1.188rem !important;
  padding-bottom: 1.188rem !important;
  vertical-align: middle;
}
