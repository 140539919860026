@import '../../../assets/scss/theme.scss';

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: $blu-03 !important;
  color: $blu-01;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPicker-Caption > div {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: $blu-01;
  color: $blu-03;
}

.subsection-title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01 !important;
}

.secondary-modal-title {
  color: $gry-01;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.127rem;
}

.order-note-title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01;
}

.order-note {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $blk-00;
}
