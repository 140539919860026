@import '../../assets/scss/theme.scss';

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.subpage-title {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 1.313rem;
  letter-spacing: 0.148rem;
  line-height: 1.813rem;
  color: $gry-01;
  padding-bottom: 0rem;
  margin-top: 2.188rem;
  margin-left: 1.875rem;
  margin-bottom: 0 m !important;
}

.prim-btn-sm {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.688rem !important;
  letter-spacing: 0.078rem;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.563rem !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  width: 8.938rem;
}

.table-headers {
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 2.25rem;
  color: $blk-00;
}

.table-cells {
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 0.688rem;
  letter-spacing: 0.078rem;
  line-height: 1.25rem;
  color: $blk-00;
  padding-top: 1.188rem !important;
  padding-bottom: 1.188rem !important;
  vertical-align: middle;
}

.page-background {
  // background: -webkit-linear-gradient(180deg,red 0%, white 100%);
  // background: -o-linear-gradient(180deg,red 0%, white 100%);
  // background: -moz-linear-gradient(180deg,red 0%, white 100%);
  background: linear-gradient(180deg,#EEEEEE 0%, white 100%);
}

