//
// Glisten - Custom Colors
//

$theme-colors: (
  'blk-00': #000000,
  'blu-01': #0073ff,
  'blu-02': #2d99ff,
  'blu-03': #ecf7ff,
  'grn-01': #136e00,
  'grn-02': #21b900,
  'grn-03': #dcfad5,
  'gry-01': #333333,
  'gry-02': #777777,
  'gry-03': #929292,
  'gry-04': #bbbbbb,
  'gry-05': #c0c0c0,
  'gry-06': #d0d0d0,
  'gry-07': #dfdfdf,
  'gry-08': #e6e6e6,
  'gry-09': #eeeeee,
  'gry-10': #f5f5f5,
  'gry-11': #f8f8f8,
  'red-01': #e02020,
  'red-02': #fff0ee,
  'wht-00': #ffffff,
  'yel-00': #fda000,
  'yel-01': #ffc440,
  'yel-02': #fbf3e3,
);

$blk-00: #000000;

$blu-01: #0073ff;
$blu-02: #2d99ff;
$blu-03: #ecf7ff;

$grn-01: #136e00;
$grn-02: #21b900;
$grn-03: #dcfad5;

$gry-01: #333333;
$gry-02: #777777;
$gry-03: #929292;
$gry-04: #bbbbbb;
$gry-05: #c0c0c0;
$gry-06: #d0d0d0;
$gry-07: #dfdfdf;
$gry-08: #e6e6e6;
$gry-09: #eeeeee;
$gry-10: #f5f5f5;
$gry-11: #f8f8f8;

$red-01: #e02020;
$red-02: #fff0ee;

$wht-00: #ffffff;

$yel-00: #fda000;
$yel-01: #ffc440;
$yel-02: #fbf3e3;
