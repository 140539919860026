@import '../../../assets/scss/theme.scss';
@import '../../../../node_modules/react-day-picker/lib/style.css';

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: $blu-03 !important;
  color: $blu-01;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.glisten-modal-body {
  padding-left: 4.375rem;
  padding-bottom: 0;
  @media only screen and (max-width: 992px) {
    padding-left: 1.375rem !important;
    padding-right: 1.375rem !important;
  }
}

.glisten-textfield-label {
  font-family: 'Avenir-Roman';
  font-size: 0.6875rem;
  letter-spacing: 1.24px;
  color: $gry-02;
  padding-top: 0.688rem;
  padding-left: 0.688rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.glisten-textfield-label-hidden {
  visibility: hidden;
}

.DayPicker-Caption > div {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.099rem;
  line-height: 1.25rem;
  color: $gry-01 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: $blu-01;
  color: $blu-03;
}

.reset-link {
  font-family: 'Poppins';
  font-weight: lighter !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.099rem;
  line-height: 0.094rem;
  color: $blu-01 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-transform: uppercase;
  background: transparent !important;
  border: none !important;
  margin-left: 0.625rem;
}

.selection-label {
  margin-bottom: 0rem !important;
}
