@import '../../assets/scss/theme.scss';

.menu-item {
  // padding: 0 40px;
  // margin: 5px 10px;
  padding-top: 1rem;
  padding-right: 2rem;
  user-select: none;
  cursor: pointer;
  border: none;
}

.arrow-next {
  position: fixed;
  right: 1rem;
}

.arrow-prev {
  position: fixed;
  left: 1rem;
}

.menu-item-wrapper.active {
  border: 0px black solid;
}
.menu-item.active {
  border: 0px black solid;
}

.menu-wrapper {
  width: 100%;
}

.scroll-menu-arrow {
  padding: 2rem;
  @media only screen and (max-width: 992px) {
    padding: 1.2rem;
  }
  color: white;
  font-size: xx-large;
  cursor: pointer;
}
